/* - - - - - - - - - - - - - - - - - -

Slate app styles

// - - - - - - - - - - - - - - - - - - */

@import "./_base/_normalize";
@import "./_base/_mixins";
@import "./_base/_colors";
@import "./_base/_structure";
@import "./_base/_type";

@import "./_modules/_from-source-modal";

.center-loading {
  display: flex;
  justify-content: center;
}