// - - - - - - - - - - - - - - - - - -

// Mixins

// - - - - - - - - - - - - - - - - - -


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Breakpoints
// Usage: @include mq(m) {}

$break-s: 688px;
$break-m: 992px;
$break-l: 1312px;
$break-xl: 1600px;

@mixin mq($media) {
	@if $media == s {
		@media only screen and (min-width: $break-s) { @content; }
	}
	@else if $media == m {
		@media only screen and (min-width: $break-m) { @content; }
	}
	@else if $media == l {
		@media only screen and (min-width: $break-l) { @content; }
	}
	@else if $media == xl {
		@media only screen and (min-width: $break-xl) { @content; }
	}
}

@mixin mq_max($media) {
	@if $media == s {
		@media only screen and (max-width: $break-s - 1) { @content; }
	}
	@else if $media == m {
		@media only screen and (max-width: $break-m - 1) { @content; }
	}
	@else if $media == l {
		@media only screen and (max-width: $break-l - 1) { @content; }
	}
	@else if $media == xl {
		@media only screen and (max-width: $break-xl - 1) { @content; }
	}
}

@mixin mq_only($media) {
	@if $media == s {
		@media only screen and (max-width: $break-s - 1) { @content; }
	}
	@else if $media == m {
		@media only screen and (min-width: $break-s) and (max-width: $break-m - 1) { @content; }
	}
	@else if $media == l {
		@media only screen and (min-width: $break-m) and (max-width: $break-l - 1) { @content; }
	}
	@else if $media == xl {
		@media only screen and (min-width: $break-l) and (max-width: $break-xl - 1) { @content; }
	}
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Transitions

$easey: cubic-bezier(0.2, 0.3, 0, 1);
$bouncey: cubic-bezier(.14,1.14,.39,1.16);