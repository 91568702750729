.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;
}
.rdt_TableHeadRow {
  min-height: 39px !important;
}

.library__image {
  width: 100%;
  min-width: 125px;
  max-height: 69px;
}

.library__image__overlay {
  pointer-events: initial;
}

fieldset.search__input--wrapper {
  width: 32%;
  float: left;
  padding: 0;
}

.search__input--form {
  display: inline-block;
  width: calc(100% - 200px);
}

input[type="text"].search__input {
  padding-left: 34px;
}

.search__input-icon {
  left: 11px;
  top: 11px;
}

#import-spots {
  font-size: 1.3rem;
  line-height: 1.95rem;
}

.loading {
  text-align: center;
  padding: 20px;
}

.list-view.not-found {
  text-align: center;
}

video {
  width: 100%;
  height: auto;
}

i.view {
  background: url('/public/img/view.svg') no-repeat;
  display: block;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
}

.slate__clip-list {
  padding-bottom: 85px;
}

.lg-sub-html {
    color: #fff !important;
}
.lg-toolbar .lg-icon {
    color: #fff !important;  
}
.lg-toolbar .lg-icon:hover {
    color: #fff !important;
}
/** hashloader */
.css-1d199hs {
  margin-left: 5px;
}

/** pb button */
.pb-container {
  display: inline-block;
  text-align: center;
  width: 100%;
}
.pb-container .pb-button {
  background: lightseagreen;
  border: 1px solid lightseagreen;
  /*border-radius: 27px;*/
  color: currentColor;
  cursor: pointer;
  /*padding: 0.7em 1em;*/
  text-decoration: none;
  text-align: center;
  /*height: 54px;*/
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  transition: background-color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s, border-radius 0.3s;
}
.pb-container .pb-button span {
  display: inherit;
  transition: opacity 0.3s 0.1s;
}
.pb-container .pb-button svg {
  height: 54px;
  width: 54px;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.pb-container .pb-button svg path {
  opacity: 0;
  fill: none;
}
.pb-container .pb-button svg.pb-progress-circle {
  animation: spin 0.9s infinite cubic-bezier(0.085, 0.260, 0.935, 0.710);
}
.pb-container .pb-button svg.pb-progress-circle path {
  stroke: currentColor;
  stroke-width: 5;
}
.pb-container .pb-button svg.pb-checkmark path,
.pb-container .pb-button svg.pb-cross path {
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 4;
}
.pb-container.disabled .pb-button {
  cursor: not-allowed;
}
.pb-container.loading .pb-button {
  width: 54px;
  border-width: 6.5px;
  border-color: #ddd;
  cursor: wait;
  background-color: transparent;
  padding: 0;
}
.pb-container.loading .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.loading .pb-button .pb-progress-circle > path {
  transition: opacity 0.15s 0.3s;
  opacity: 1;
}
.pb-container.success .pb-button {
  border-color: #A0D468;
  background-color: #A0D468;
}
.pb-container.success .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.success .pb-button .pb-checkmark > path {
  opacity: 1;
}
.pb-container.error .pb-button {
  border-color: #ED5565;
  background-color: #ED5565;
}
.pb-container.error .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.error .pb-button .pb-cross > path {
  opacity: 1;
}
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: center center;
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
    transform-origin: center center;
  }
}