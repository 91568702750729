// - - - - - - - - - - - - - - - - - -

// Typography

// - - - - - - - - - - - - - - - - - -

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: var(--text-strong-color);
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: 600;
}

p {
	margin: 10px 0 0;

	&:first-child {
		margin-top: 0;
	}
}

a {
	color: var(--text-strong-color);
	text-decoration: none;
	transition: color 0.1s $easey;

	&:hover {
		color: var(--accent-color);
	}
}

input[type="text"],
textarea {
	width: 100%;
	height: 40px;
	border: 1px solid var(--border-color);
	border-radius: 3px;
	padding: 0 12px;
	font-size: 13px;
	color: var(--text-strong-color);
	transition: all 0.1s $easey;
	-webkit-appearance: none;

	&::-webkit-input-placeholder {
		color: var(--text-light-color);
	}
	&::-moz-placeholder {
		color: var(--text-light-color);
	}
	&:-ms-input-placeholder {
		color: var(--text-light-color);
	}
	&:-moz-placeholder {
		color: var(--text-light-color);
	}

	&:focus {
		outline: none;
		border-color: var(--text-light-color);
		box-shadow: 0px 5px 20px unquote("hsla(var(--background-dark-color-hsl),0.06)");
	}
}

.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 0;
	height: 33px;
	padding: 0 12px;
	border-radius: 3px;
	background: var(--border-color);
	font-size: 13px;
	line-height: 1;
	font-weight: 600;
	color: var(--text-medium-color);
	white-space: nowrap;
	transition: all 0.2s $easey;
	cursor: pointer;

	&:focus {
		outline: none;
	}

	&:hover {
		color: var(--text-strong-color);
	}

	svg {
		height: 13px;
		fill: currentColor;

		&:first-child {
			margin-right: 8px;
		}

		&:last-child {
			margin-left: 8px;
		}

		&:first-child:last-child {
			margin: 0;
		}
	}

	&--full {
		width: 100%;
		text-align: center;
	}

	&--wide {

		@include mq(m) {
			padding: 0 22px;
		}
	}

	&--icon {
		width: 33px;
		padding: 0;
	}

	&--item {
		width: 100%;
		height: 50px;
		margin-top: 3px;

		svg {
			height: 16px;
		}
	}

	&--input {
		width: 100%;
		height: 40px;
	}

	&--large {
		height: 50px;
		padding: 0 40px;
		font-size: 16px;

		svg {
			height: 16px;

			&:first-child {
				margin-right: 8px;
			}

			&:last-child {
				margin-left: 8px;
			}
		}

		&--icon {
			width: 50px;
		}
	}

	&--small {
		height: 21px;
		line-height: 21px;
		padding: 0 5px;
		font-size: 10px;

		svg {
			height: 10px;
			margin-top: -1px;

			&:first-child {
				margin-right: 4px;
			}

			&:last-child {
				margin-left: 4px;
			}
		}
	}

	&--primary {
		background: var(--accent-color);
		color: var(--text-inverse-color);

		&:hover {
			background: hsl(var(--accent-color-h),var(--accent-color-s),37%);
			color: var(--text-inverse-color);
		}
	}

	&--primary-outline {
		background: var(--background-light-color);
		border: 1px solid var(--accent-color);
		color: var(--accent-color);

		&:hover {
			background: var(--accent-color);
			color: var(--text-inverse-color);
		}
	}

	&--secondary {
		background: var(--accent-blue-color);
		color: var(--text-inverse-color);

		&:hover {
			background: hsl(var(--accent-blue-color-h),var(--accent-blue-color-s),53%);
			color: var(--text-inverse-color);
		}
	}

	&--secondary-outline {
		background: var(--background-light-color);
		border: 1px solid var(--accent-blue-color);
		color: var(--accent-blue-color);

		&:hover {
			border-color: var(--accent-blue-color);
			color: hsl(var(--accent-blue-color-h),var(--accent-blue-color-s),53%);
		}
	}

	&--secondary-outline-inverse {
		background: transparent;
		border: 1px solid rgba(white,0.5);
		color: var(--background-light-color);

		&:hover {
			background: var(--background-light-color);
			color: var(--accent-blue-color);
		}
	}

	&--medium {
		background: var(--text-medium-color);
		color: var(--text-inverse-color);

		&:hover {
			background: hsl(var(--text-medium-color-h),var(--text-medium-color-s),66%);
			color: var(--text-inverse-color);
		}
	}

	&--light {
		background: var(--border-color);
		color: hsl(var(--text-medium-color-h), var(--text-medium-color-s), 46%);

		&:hover {
			background: hsl(var(--border-color-h),var(--border-color-s),88%);
			color: var(--text-strong-color);
		}
	}

	&--light-outline {
		background: var(--background-light-color);
		border: 1px solid var(--border-color);
		color: hsl(var(--text-medium-color-h), var(--text-medium-color-s), 46%);

		&:hover {
			color: var(--text-strong-color);
		}
	}

	&--delete {
		background: var(--red-light-color);
		color: var(--red-color);

		&:hover {
			background: var(--red-color);
			color: var(--text-inverse-color);
		}
	}

	&--disabled,
	&[disabled] {
		pointer-events: none;
		background: var(--border-color);
		color: var(--text-light-color);
	}

	&--icon-spin {

		svg {
			animation-name: button_spin;
			animation-duration: 0.8s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}
	}
}

@keyframes button_spin { 
	from { 
		transform: rotate(0deg); 
	} to { 
		transform: rotate(360deg); 
	}
}