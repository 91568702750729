// - - - - - - - - - - - - - - - - - -

// Colors

// - - - - - - - - - - - - - - - - - -

@mixin colors {

	--background-color-h: 200;
	--background-color-s: 16%;
	--background-color-l: 96%;
	--background-color-hsl: var(--background-color-h), var(--background-color-s), var(--background-color-l);
	--background-color: hsl(var(--background-color-hsl));

	--background-alt-color-h: 210;
	--background-alt-color-s: 33%;
	--background-alt-color-l: 99%;
	--background-alt-color-hsl: var(--background-alt-color-h), var(--background-alt-color-s), var(--background-alt-color-l);
	--background-alt-color: hsl(var(--background-alt-color-hsl));

	--background-light-color-h: 0;
	--background-light-color-s: 0%;
	--background-light-color-l: 100%;
	--background-light-color-hsl: var(--background-light-color-h), var(--background-light-color-s), var(--background-light-color-l);
	--background-light-color: hsl(var(--background-light-color-hsl));

	--background-dark-color-h: 207;
	--background-dark-color-s: 35%;
	--background-dark-color-l: 15%;
	--background-dark-color-hsl: var(--background-dark-color-h), var(--background-dark-color-s), var(--background-dark-color-l);
	--background-dark-color: hsl(var(--background-dark-color-hsl));

	--border-color-h: 200;
	--border-color-s: 14%;
	--border-color-l: 91%;
	--border-color-hsl: var(--border-color-h), var(--border-color-s), var(--border-color-l);
	--border-color: hsl(var(--border-color-hsl));

	--menu-background-color-h: 207;
	--menu-background-color-s: 31%;
	--menu-background-color-l: 21%;
	--menu-background-color-hsl: var(--menu-background-color-h), var(--menu-background-color-s), var(--menu-background-color-l);
	--menu-background-color: hsl(var(--menu-background-color-hsl));

	--menu-background-dark-color-h: 207;
	--menu-background-dark-color-s: 35%;
	--menu-background-dark-color-l: 15%;
	--menu-background-dark-color-hsl: var(--menu-background-dark-color-h), var(--menu-background-dark-color-s), var(--menu-background-dark-color-l);
	--menu-background-dark-color: hsl(var(--menu-background-dark-color-hsl));

	--menu-background-medium-color-h: 208;
	--menu-background-medium-color-s: 31%;
	--menu-background-medium-color-l: 26%;
	--menu-background-medium-color-hsl: var(--menu-background-medium-color-h), var(--menu-background-medium-color-s), var(--menu-background-medium-color-l);
	--menu-background-medium-color: hsl(var(--menu-background-medium-color-hsl));

	--menu-background-light-color-h: 208;
	--menu-background-light-color-s: 30%;
	--menu-background-light-color-l: 31%;
	--menu-background-light-color-hsl: var(--menu-background-light-color-h), var(--menu-background-light-color-s), var(--menu-background-light-color-l);
	--menu-background-light-color: hsl(var(--menu-background-light-color-hsl));

	--text-strong-color-h: 207;
	--text-strong-color-s: 31%;
	--text-strong-color-l: 21%;
	--text-strong-color-hsl: var(--text-strong-color-h), var(--text-strong-color-s), var(--text-strong-color-l);
	--text-strong-color: hsl(var(--text-strong-color-hsl));

	--text-medium-color-h: 208;
	--text-medium-color-s: 12%;
	--text-medium-color-l: 60%;
	--text-medium-color-hsl: var(--text-medium-color-h), var(--text-medium-color-s), var(--text-medium-color-l);
	--text-medium-color: hsl(var(--text-medium-color-hsl));

	--text-light-color-h: 208;
	--text-light-color-s: 16%;
	--text-light-color-l: 78%;
	--text-light-color-hsl: var(--text-light-color-h), var(--text-light-color-s), var(--text-light-color-l);
	--text-light-color: hsl(var(--text-light-color-hsl));

	--text-inverse-color-h: 0;
	--text-inverse-color-s: 0%;
	--text-inverse-color-l: 100%;
	--text-inverse-color-hsl: var(--text-inverse-color-h), var(--text-inverse-color-s), var(--text-inverse-color-l);
	--text-inverse-color: hsl(var(--text-inverse-color-hsl));

	--accent-color-h: 184;
	--accent-color-s: 100%;
	--accent-color-l: 33%;
	--accent-color-hsl: var(--accent-color-h), var(--accent-color-s), var(--accent-color-l);
	--accent-color: hsl(var(--accent-color-hsl));

	--accent-faded-color-h: 186;
	--accent-faded-color-s: 42%;
	--accent-faded-color-l: 80%;
	--accent-faded-color-hsl: var(--accent-faded-color-h), var(--accent-faded-color-s), var(--accent-faded-color-l);
	--accent-faded-color: hsl(var(--accent-faded-color-hsl));

	--accent-blue-color-h: 209;
	--accent-blue-color-s: 47%;
	--accent-blue-color-l: 63%;
	--accent-blue-color-hsl: var(--accent-blue-color-h), var(--accent-blue-color-s), var(--accent-blue-color-l);
	--accent-blue-color: hsl(var(--accent-blue-color-hsl));

	--red-color-h: 0;
	--red-color-s: 73%;
	--red-color-l: 60%;
	--red-color-hsl: var(--red-color-h), var(--red-color-s), var(--red-color-l);
	--red-color: hsl(var(--red-color-hsl));

	--red-light-color-h: 356;
	--red-light-color-s: 71%;
	--red-light-color-l: 96%;
	--red-light-color-hsl: var(--red-light-color-h), var(--red-light-color-s), var(--red-light-color-l);
	--red-light-color: hsl(var(--red-light-color-hsl));
	
}

:root {
	@include colors;
}